import React from "react"
import { Container } from "react-bootstrap"
import ReactTextRotator from "react-text-rotator"

const content = [
  {
    text: "Auf geht's!",
    animation: "fade",
  },
  {
    text: "Wo wir sind ist oben.",
    animation: "fade",
  },
  {
    text: "Alles gut bedacht.",
    animation: "fade",
  },
]

export default props => (
  <div>
    <div className={"arrow-up " + props.Color} />
    <div className={"contentbox " + props.Color}>
      <Container className="text-center">
        <h2>
          <ReactTextRotator content={content} time={3000} startDelay={0} />
        </h2>
        <hr />
      </Container>
    </div>
  </div>
)
