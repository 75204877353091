import React, { Component } from "react"
import { Container } from "react-bootstrap"

export default class boxChristmasVacation extends Component {
  render() {
    const startDate = new Date("2019-12-16T00:00:00")
    const endDate = new Date("2020-01-05T00:00:00")
    const today = Date.now()
    const isVisible = today >= startDate && today <= endDate
    return (
      <div>
        {isVisible ? (
          <div>
            <div className="arrow-up red" />
            <div className="arrow-down red" />
            <div className="contentbox christmas">
              <Container className="text-left">
                <h4>
                  <p>Sehr geehrte Kunden und Geschäftspartner,</p>
                  <p>
                    vom 21.12.2019 bis zum 05.01.2020 machen wir eine
                    Verschnaufpause und gehen in die Betriebsferien. In dieser
                    Zeit bleibt der Betrieb geschlossen. Ab dem 06.01.2020 sind
                    wir wie gewohnt wieder für Sie da.
                  </p>
                  <p>
                    Wir bedanken uns für Ihre Treue und die angenehme
                    Zusammenarbeit und wünschen Ihnen und Ihren Familien ein
                    besinnliches Weihnachtsfest und einen guten Start ins neue
                    Jahr.
                  </p>
                  <p>
                    Ihr Team der Dachdeckerei Michael Bürgel e.K. Inh. Miro Graf
                  </p>
                </h4>
                <hr />
              </Container>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
}
